css
/* Set font family and size */
body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  
}

/* Set background color */
body {
  background-color: #f5f5f5;
}

/* Set color scheme */
:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --light-color: #f8f9fa;
  --dark-color: #343a40;
  margin-left: 2rem;
}

/* Set color for headings */
h1, h2, h3, h4, h5, h6 {
  color: var(--dark-color);
  
}

/* Set color for links */
a {
  
  color: var(--primary-color);
  text-decoration: none;
}

/* Add hover effect for links */
a:hover {
  text-decoration: underline;
}

/* Set color for buttons */

button {
  background-color: var(--primary-color);
  color: var(--light-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

/* Add hover effect for buttons */
button:hover {
  background-color: var(--secondary-color);
}

/* Set card layout */
.card {
  
  background-color: var(--light-color);
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

/* Set color for form inputs */
input, textarea {
  border: none;
  border-radius: 5px;
  padding: 10px;
  background-color: var(--light-color);
  color: var(--dark-color);
  margin-top: 2rem;
  
}

.makeStyles-img-2 { 
  /* Add your styles for the image here */ 
  transition: transform 0.3s ease-in-out; 
} 
 
.makeStyles-img-2:hover { 
  /* Add your styles for the animation on hover here */ 
  transform: scale(1.1); 
} 

/* Add focus effect for form inputs */
input:focus, textarea:focus {
  outline: none;
  box-shadow: 0px 0px 2px var(--primary-color);
  
}

/* Set color for form labels */
label {
  
  color: var(--dark-color);
  font-weight: bold;
  margin-bottom: 5px;
}

/* Set color for form buttons */
form button {
  background-color: var(--primary-color);
  color: var(--light-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  
}

.tokens-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(508px, 1fr));
  gap: 10px;
  margin-top: 2rem;
  margin-right: 2rem;
}